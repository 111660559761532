var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"PublicMapQueryPage pt-0",attrs:{"fluid":"","grid-list-lg":"","id":"results"}},[_c('page-section',{attrs:{"theme":"blue","centered":"","loading":_vm.publicGaugesLoading}},[_c('h1',[_vm._v("If you build it. The data will come.")])]),_c('page-section',[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"PublicMapQueryPage__wrapper"},[_c('public-map',{directives:[{name:"lock-on-scroll",rawName:"v-lock-on-scroll"}],attrs:{"hide-layers-control":""}}),(_vm.showActions)?_c('div',{staticClass:"PublicMapQueryPage__action-overlay"},[_c('p',{staticClass:"white--text font-weight-bold text-center pa-6 pb-0"},[_vm._v(" Move the extents of the map over the desired gauges you'd like to query. Fill out the fields on the right and submit the form. Your results will appear below the map. ")]),_c('v-btn',{attrs:{"color":"accent","depressed":"","rounded":"","large":""},on:{"click":function($event){_vm.showActions = false}}},[_vm._v(" Got it ")])],1):_vm._e()],1)]),_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"PublicMapQueryPage_form"},[_c('form',{staticClass:"form",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.formSubmit(_vm.submit)}}},[_c('v-select',{staticClass:"white mb-3",attrs:{"outlined":"","label":"Query Type","dense":"","hide-details":"","items":[
                'Gauges',
                'Gauges and Logs'
              ]},model:{value:(_vm.queryType),callback:function ($$v) {_vm.queryType=$$v},expression:"queryType"}}),(_vm.queryType === 'Gauges and Logs')?_c('checkbox',{staticClass:"ml-6 mb-4",attrs:{"label":"Include quantile report data","name":"includeReport"},model:{value:(_vm.form.includeReport),callback:function ($$v) {_vm.$set(_vm.form, "includeReport", $$v)},expression:"form.includeReport"}}):_vm._e(),_c('div',{staticClass:"mb-3"},[_c('date-menu',{attrs:{"name":"from","label":"Log Date From","hide-actions":"","dense":"","return-format":_vm.datePicker,"picker-props":{
                  max: _vm.datePicker(new Date())
                },"text-field-props":{
                  hideDetails: true,
                  singleLine: false,
                  appendIcon: ''
                }},model:{value:(_vm.form.logDateStart),callback:function ($$v) {_vm.$set(_vm.form, "logDateStart", $$v)},expression:"form.logDateStart"}})],1),_c('div',{staticClass:"mb-3"},[_c('date-menu',{attrs:{"name":"to","label":"Log Date To","hide-actions":"","dense":"","return-format":_vm.datePicker,"picker-props":{
                  max: _vm.datePicker(new Date())
                },"text-field-props":{
                  hideDetails: true,
                  singleLine: false,
                  appendIcon: ''
                }},model:{value:(_vm.form.logDateEnd),callback:function ($$v) {_vm.$set(_vm.form, "logDateEnd", $$v)},expression:"form.logDateEnd"}})],1),_c('v-textarea',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"value":_vm.bounds,"error-messages":_vm.errors.collect('bounds'),"data-vv-name":"bounds","name":"bounds","label":"Bounds (extent)","rows":"4","hint":"Read only","outlined":"","readonly":""}}),_c('v-btn',{staticClass:"form__submit mt-3",attrs:{"type":"submit","color":"accent","depressed":"","rounded":"","large":"","block":"","loading":_vm.loading}},[_vm._v(" Submit ")])],1)])])],1)],1),_c('page-section',{staticClass:"PublicMapQueryPage__results"},[_c('h2',[_vm._v(" Results ")]),_c('h4',[_vm._v("URL")]),_c('div',{staticClass:"PublicMapQueryPage__copy-section mb-6"},[_c('pre',[_vm._v(_vm._s(_vm.generatedUrl))]),_c('v-btn',{staticClass:"PublicMapQueryPage__copy-section__btn",attrs:{"color":"gray","rounded":"","depressed":"","small":""},on:{"click":_vm.copyUrl}},[_vm._v(" Copy "),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v(" "+_vm._s(_vm.copyUrlSuccess ? 'mdi-check' : 'mdi-content-copy ')+" ")])],1)],1),_c('h4',[_vm._v("Response")]),_c('div',{staticClass:"PublicMapQueryPage__copy-section"},[_c('pre',[_vm._v(_vm._s(_vm.results))]),_c('v-btn',{staticClass:"PublicMapQueryPage__copy-section__btn",attrs:{"color":"gray","rounded":"","depressed":"","small":""},on:{"click":_vm.copyResults}},[_vm._v(" Copy "),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v(" "+_vm._s(_vm.copyResultsSuccess ? 'mdi-check' : 'mdi-content-copy ')+" ")])],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }