/**
 * THIS FILE CONTAINS FUNCTIONS AND CONSTANTS THAT ARE DUPLICATED
 * IN THE BACKEDN CODE BACK UNDER THE DIRECTORY:
 * /src/utils/clientShared.js
 * 
 * ANY CHANGES TO THIS FILE WILL MOST LIKELY RESULT IN REFACTORS IN THE BACKEND TO KEEP IN SYNCH
 */

import {
  dateToLocal,
  dateToDay,
  isBetween,
  isSame,
  dateDifference
} from '@/utils/dateUtils'
import { cloneDeep } from 'lodash'

const startingYear = 1981;
export const historicStart = `${startingYear}-01-01`;
export const quantileKeys = ['Q1', 'Q2', 'Q3', 'Q4', 'Q5']

export function massageTheoreticalData(data) {
  return data.reduce((accum, value, index) => {
    // if first record, force to be 0.00
    if (index === 0) { 
      return accum.concat({
        x: dateToLocal(value[0], ["YYYY-MM-DD"]),
        y: 0.00
      })
    }

    // some values are -999, setting those to 0
    if (value[1] < 0) value[1] = 0

    // if there was actual precip, add it to the previous value
    if (value[1] >= 0) {
      return accum.concat({
        x: dateToLocal(value[0], ["YYYY-MM-DD"]),
        y: value[1] + accum[accum.length - 1].y
      })
    }

    return accum
  }, [])
}

export function massageHistoricalData(data) {
  return quantileKeys.reduce((accum, value, index) => {
    accum[value] = []
    let tempCumulative = 0

    data.forEach((data, i) => {
      accum[value].push({
        x: data[0],
        y: data[index + 1]
      })  
    })
    
    return accum
  }, {})  
}

export function getEstimatedDataByDate (date, data) {
  return data.find((obj) => {
    return dateToDay(date) === dateToDay(obj.x)
  }) || {}
}

export function getHistoricalDataByDate (date, data) {
  return quantileKeys.reduce((accum, q) => {
    const record = (data[q] || []).find((obj) => {
      return dateToDay(date) === dateToDay(obj.x)
    })

    accum[q] = record && record.y

    return accum
  }, {})
}

export const quantileLabelLookup = (val = '') => {
  return {
    q1: 'Extremely Dry',
    q2: 'Very Dry',
    q3: 'Dry',
    q4: 'Wet',
    q5: 'Very Wet'
  }[val.toLowerCase()]
}

/**
 * @name getSpiLabel
 * @descripiton determine the SPI label of a gauge reading
 * @param {Number} record the difference between two different observations
 * @param {Number[]} quantile a single days quantile record
 * @param {Boolean} log show dev logs
 * @returns 
 */
export const getSpiLabel = (record, quantile, log) => {
  const labels = Object.keys(quantile)
  let returnValue = 'Extremely Dry'

  if (log) {
    console.log('SPI labels: ', labels)
    console.log('SPI quantile: ', quantile)
    console.log('Log record: ', record)
  }

  for (let i = 0; i < labels.length; i++) {
    if (log) {
      console.log('Quantile value: ', quantile[labels[i]])
    }

    if (record <= quantile[labels[i]]) {
      break
    } else {
      const idx = i === labels.length - 1 ? i : i + 1
      returnValue = quantileLabelLookup(labels[idx])
    }
  }

  return returnValue
}

export const sortLogsByDate = (logs = [], order = 'desc') => {
  return cloneDeep(logs).sort((a, b) => {
    const aDate = dateToLocal(a.logDateTime)
    const bDate = dateToLocal(b.logDateTime)

    if (aDate < bDate) {
      return (order === 'desc') ? 1 : -1
    }

    if (aDate > bDate) {
      return (order === 'desc') ? -1 : 1
    }
    // a must be equal to b
    return 0
  })
}

export const cumulateLogs = ({ logs = [], modifier = 0, sort = true }) => {
  const sortedLogs = sort ? sortLogsByDate(logs, 'asc') : logs
  let adjustedModifier = modifier

  return sortedLogs.reduce((outcome, log, index) => {
    const reading = index === 0 || log.isReset
      ? log.reading
      : Number(log.reading) - adjustedModifier

    const record = index === 0 || log.isReset
      ? 0
      : (reading - Number(outcome[index - 1].reading))

    const cumulative = index === 0
      ? record
      : outcome[index - 1].cumulative + record

    // if a reset log is encountered, reset the modifier to 0
    if (log.isReset && index !== 0) {
      adjustedModifier = 0
    }

    return outcome.concat({
      ...log,
      reading,
      record,
      cumulative
    })
  }, [])
}

export const cumulateLogsWithModifier = ({ logs = [], from, to }) => {
  let prependModifiedStart = false
  let prependModifiedEnd = false

  const logsWithinRange = logs.reduce((outcome, log, index) => {
    const logDateTime = dateToLocal(log.logDateTime)
    const newLog = { ...log, ogIndex: index }

    // return logDateTime >= f && logDateTime <= t
    return isBetween(logDateTime, from, to) ? outcome.concat(newLog) : outcome
  }, [])

  // check if first log in list wasn't the original 0 index
  // this tells us there was an observation BEFORE the START date
  if (
    logsWithinRange.length &&
    (
      (logsWithinRange[0].ogIndex > 0 &&
      !isSame(from, logsWithinRange[0].logDateTime))
    )
  ) {
    logsWithinRange.splice(0, 0, logs[logsWithinRange[0].ogIndex - 1])
    prependModifiedStart = true
  }

  // check if last log in list wasn't the original last index
  // this tells us there was an observation AFTER the END date
  if (
    logsWithinRange.length &&
    (
      (logsWithinRange[logsWithinRange.length - 1].ogIndex < logs.length - 1 &&
      !isSame(to, logsWithinRange[logsWithinRange.length - 1].logDateTime))
    )
  ) {
    logsWithinRange.push(logs[logsWithinRange[logsWithinRange.length - 1].ogIndex + 1])
    prependModifiedEnd = true
  }

  let logsCalculated = cumulateLogs({ logs: logsWithinRange, sort: false })

  if (prependModifiedStart) {
    const firstAndSecondDiff = dateDifference(logsCalculated[0].logDateTime, logsCalculated[1].logDateTime)
    const firstAndStartDiff = dateDifference(logsCalculated[0].logDateTime, from)
    const dailyRecordAvg = logsCalculated[1].record / firstAndSecondDiff
    const recordToMinus = dailyRecordAvg * firstAndStartDiff

    logsCalculated[0].logDateTime = dateToLocal(from)
    logsCalculated = cumulateLogs({ logs: logsCalculated, sort: false, modifier: recordToMinus })
  }

  if (prependModifiedEnd) {
    const lastDayDiff = dateDifference(logsCalculated[logsCalculated.length - 2].logDateTime, logsCalculated[logsCalculated.length - 1].logDateTime)
    const lastEndDayDiff = dateDifference(logsCalculated[logsCalculated.length - 2].logDateTime, to)
    const dailyRecordAvg = logsCalculated[logsCalculated.length - 1].record / lastDayDiff
    const remainingDayTotal = dailyRecordAvg * lastEndDayDiff

    logsCalculated[logsCalculated.length - 1].logDateTime = dateToLocal(to)
    logsCalculated[logsCalculated.length - 1].cumulative = logsCalculated[logsCalculated.length - 2].cumulative + remainingDayTotal
  }

  return logsCalculated
}
