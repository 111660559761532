<template>
  <div class="map-controller">
    <l-map
      :mapId="mapId"
      :options="{
        center: [38.79, -98.53],
        zoom: 4
      }"
      :events="['zoomend', 'moveend']"
      enable-bus
      @ready="handleMapReady"
    >
      <l-tile-layer v-bind="basemapOptions" :key="selectedBasemap"/>

      <gauges-layer 
        v-if="selectedLayers.includes('myGauges') && gauges.length"
        :gauges="gauges"
        v-on:gauge-click="handleMarkerClick"/>

      <public-gauges-layer
        v-if="selectedLayers.includes('publicGauges')"
        :gauges="publicGauges"
        v-on:loading="(val) => { loading = val }"
        v-on:gauge-click="handleMarkerClick"/>

      <l-marker
        v-if="selectedGauge.latLng"
        :key="selectedGauge.gid"
        :latlng="selectedGauge.latLng"
        :options="selectedGauge"/>
    </l-map>

    <!-- layers icon toggles map drawer -->
    <div class="map-controller__controls" @click="showControls = !showControls" v-if="!hideLayersControl">
      <v-icon>layers</v-icon>
    </div>

    <map-drawer v-model="showControls" v-on:close="showControls = !showControls">
      <ul>
        <li v-for="(basemap) in basemaps" :key="basemap.id">
          <radio :label="basemap.label" name="basemaps" :value="basemap.id" v-model="selectedBasemap"></radio>
        </li>
      </ul>

      <ul>
        <li v-for="layer in layers" :key="layer.name">
          <checkbox :label="layer.label" :name="layer.name" :value="layer.name" v-model="selectedLayers"></checkbox>
        </li>
      </ul>
    </map-drawer>

    <map-drawer v-model="showDetailDrawer" v-on:close="handleDetailsClose" slot="drawer">
      <div class="map-controller__details">
        <div class="map-controller__details__info">
          <h4>{{selectedGauge.title}}</h4>
          <div>
            <strong>Total Observations:</strong>
            {{
              (selectedGauge.public)
                ? selectedGauge.numLogs
                : getLogCount(selectedGauge.gid)
            }}
          </div>
          <div><strong>Last Observation:</strong> {{dateToDayShort(selectedGauge.lastLogDate)}}</div>
          <!-- <div><strong>Cumulative:</strong> {{decimalDoublePad(selectedGauge.totalInches)}}"</div> -->
        </div>

        <div class="map-controller__image" v-if="selectedGauge.image">
          <img :src="selectedGauge.image"/>
        </div>

        <div class="map-controller__details__report">
          <router-link
            target="_blank"
            rel="noopener"
            :to="{
              name: 'publicReports',
              query: {
                gids: selectedGauge.gid,
                prebuilt: true
              }
            }">
            <gauge-report-loader v-if="selectedGauge.gid"
              small
              :gid="selectedGauge.gid"
              :click-callback="() => {}" />
          </router-link>
        </div>
      </div>
    </map-drawer>

    <spinner v-if="loading" class="map-controller__loader" :size="25" :width="3" color="black"></spinner>
  </div>
</template>

<script>
  import LMap from '@vue-mapp-kit/leaflet/lib/LMap/LMap.vue'
  import LMarker from '@vue-mapp-kit/leaflet/lib/ui/LMarker/LMarker.vue'
  import LTileLayer from '@vue-mapp-kit/leaflet/lib/raster/LTileLayer/LTileLayer.vue'
  import { mapGetters } from 'vuex'
  import GaugesLayer from '@/components/_ui/Map/layers/GaugesLayer'
  import PublicGaugesLayer from '@/components/_ui/Map/layers/PublicGaugesLayer'
  import GaugeReportLoader from '@/components/GaugeReportLoader/GaugeReportLoader'
  import { dateToDayShort } from '@/utils/dateUtils'
  import { commonTileLayer, satelliteTileLayer } from '@/utils/leafletUtils'
  import { isEmpty } from 'lodash'
  import { 
    myGaugeIcon
  } from '@/utils/leafletUtils'

  export default {
    name: 'map-controller',

    components: {
      LMap,
      LMarker,
      LTileLayer,
      GaugesLayer,
      GaugeReportLoader,
      PublicGaugesLayer
    },

    watch: {
      selectedGauge: {
        handler(val) {
          this.showDetailDrawer = !isEmpty(val)
        },
        immediate: true
      }
    },

    props: {
      mapId: {
        type: String,
        default: 'mainMap'
      },
      hideLayersControl: Boolean,
      gauges: {
        type: Array,
        default: () => ([])
      },
      publicGauges: {
        type: Array,
        default: () => ([])
      },
      private: Boolean
    },

    data() {
      return {
        loading: false,
        dateToDayShort,
        showControls: false,
        showDetailDrawer: false,
        selectedBasemap: 'street',
        selectedGauge: {},
        selectedLayers: this.private ? ['myGauges'] : ['publicGauges'],
        basemaps: [
          commonTileLayer(),
          satelliteTileLayer()
        ],
      }
    },

    computed: {
      ...mapGetters(['getMap', 'getAnyGaugeById', 'getLogCount']),
      basemapOptions() {
        return this.basemaps.find((basemap) => {
          return basemap.id === this.selectedBasemap
        })
      },
      layers() {
        let l = [
          { name: 'myGauges', label: 'My Gauges' },
          { name: 'publicGauges', label: 'Public Gauges' }
        ]

        return this.private
          ? l
          : [l[1]]
      }
    },

    created () {
      // non reactive
      this.leafletMap = null
    },

    methods: {
      handleMapReady(map) {
        this.leafletMap = map
        this.leafletMap.zoomControl.setPosition('bottomright')
      },
      handleMarkerClick({ event }) {
        const {
          _latlng,
          options,
          options: {
            gid
          } = {}
        } = event.sourceTarget || event
        
        this.leafletMap.setView(_latlng)

        if (gid && !isEmpty(this.getAnyGaugeById(gid))) {
          this.selectedGauge = {
            ...options,
            latLng: _latlng,
            icon: myGaugeIcon()
          }
        }
      },
      handleDetailsClose() {
        this.selectedGauge = {}
      },
    }
  }
</script>

<style lang="scss">
  .map-controller {
    position: relative;
    height: 100%;
    overflow: hidden;

    .map {
      position: relative;
      z-index: 1;
      height: 100%;
    }

    .map__mount {
      width: 100%;
      height: 100%;
    }

    .map-controller__controls {
      position: absolute;
      left: ($gutter-common / 2);
      top: ($gutter-common / 2);
      padding: 5px;
      background-color: #fff;
      box-shadow: 0px 0px 10px 0px rgba(#000, 0.3);
      z-index: 900;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .material-icons {
        color: #000;
      }
    }

    .map-controller__loader {
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 1;
    }

    .map-controller__details {
      h3 {
        margin-top: $gutter-common;
      }
    }

    .map-controller__image {
      margin-top: ($gutter-common / 2);
      img {
        max-width: 175px;
      }
    }

    .map-controller__details__info {
      display: flex;
      flex-direction: column;
      font-size: $font-size-small;
      max-width: 175px;
    }

    .map-controller__details__report {
      margin-top: ($gutter-common / 2);

      .tooltip {
        display: none;
      }
    }

    .map-controller__details__report-msg {
      font-size: $font-size-small;
      line-height: 1em;
    }

    @media(max-width: $desktop-breakpoint) {
      .map-controller__details {
        h3 {
          margin-top: 0;
        }
      }
      .map-controller__details__info {
        
      }
      .map-controller__details__report {
        position: absolute;
        top: ($gutter-common / 2);
        right: ($gutter-common / 2);
      }
    }
  }
</style>

