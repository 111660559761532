import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

import Login from '@/views/User/Login/Login'
import SignUp from '@/views/User/SignUp/SignUp'
import Confirm from '@/views/User/Confirm/Confirm'
import ConfirmEmail from '@/views/User/ConfirmEmail/ConfirmEmail'
import ForgotPass from '@/views/User/ForgotPass'
import ChangePassword from '@/views/User/ChangePassword'

import Home from '@/views/Home/Home'
import Solutions from '@/views/Solutions/Solutions'
import Support from '@/views/Support/Support'
import About from '@/views/About/About'
import Contact from '@/views/Contact/Contact'

import Dashboard from '@/views/Dashboard/Dashboard'
import DashboardContainer from '@/views/Dashboard/DashboardContainer'
import Profile from '@/views/Profile/Profile'
import Helpers from '@/views/Helpers/Helpers'
import HelpersAdd from '@/views/Helpers/HelpersAdd'
import HelpersEdit from '@/views/Helpers/HelpersEdit'
import Export from '@/views/Export/Export'

// TODO change gauge/gauges component name 
// TODO might not need gaugemgr
// TODO potentially deprecating gauge managers
import Gauge from '@/views/Gauges/Gauge'
import GaugeAdd from '@/views/Gauges/GaugeAdd/GaugeAdd'
import GaugeAddSuccess from '@/views/Gauges/GaugeAdd/GaugeAddSuccess'
import GaugeEmpty from '@/views/Gauges/GaugeEmpty/GaugeEmpty'
import GaugeEdit from '@/views/Gauges/GaugeEdit/GaugeEdit'
import GaugeDetail from '@/views/Gauges/GaugeDetail/GaugeDetail'

import LogAdd from '@/views/Logs/LogAdd/LogAdd'
import LogMultiAdd from '@/views/Logs/LogMultiAdd/LogMultiAdd'
import LogAddSuccess from '@/views/Logs/LogAdd/LogAddSuccess'

import PublicContainer from '@/views/Public/PublicContainer'
import PublicMapPage from '@/views/Public/PublicMapPage'
import PublicMapQueryPage from '@/views/Public/PublicMapQueryPage'

const ReportsContainer = () => import ('@/views/Reports/ReportsContainer')
const Reports = () => import ('@/views/Reports/Reports')
const ReportsBuilder = () => import ('@/views/Reports/ReportsBuilder/ReportsBuilder')

import Help from '@/views/Help/Help'
import Terms from '@/views/Terms'
import Privacy from '@/views/Privacy'

import { PAGE_TITLES, PAGE_TITLE_SUFFIX } from '@/utils/constants'

Vue.use(Router)

async function requireAuth(to, from, next) {
  const logoutPayload = {
    path: '/login',
    query: { redirect: to.fullPath }
  }

  try {
    // checkAndGetUserAuth returns true or false
    // if checkAndGetUserAuth threw an error - prerender would break
    // which is why we are setting an authed boolean
    let authed = await store.dispatch('checkAndGetUserAuth')
    
    ;(authed) ? next() : next(logoutPayload)
  } catch (error) {
    // still redirect to login incase there were any issues
    next(logoutPayload)
  }
}

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    { 
      path: '/', 
      component: Home
    },
    { path: '/home', name: 'home', redirect: '/' },
    { 
      path: '/solutions',
      name: 'solutions',
      component: Solutions
    },
    { path: '/results', redirect: '/public/map' },
    {
      path: '/public-map',
      children: [
        {
          path: '',
          redirect: { name: 'publicMap' }
        },
        { 
          path: 'gauge/:gid/report',
          name: 'publicGaugeReport',
          redirect: to => {
            const {
              params: {
                gid
              } = {}
            } = to

            return {
              name: 'publicReports',
              query: {
                gids: gid
              }
            }
          }
        }
      ]
    },
    { 
      path: '/public', 
      component: PublicContainer,
      children: [
        {
          path: 'map',
          component: PublicMapPage,
          name: 'publicMap'
        },
        {
          path: 'reports/:rid?',
          name: 'publicReports',
          component: ReportsBuilder,
          props: true
        },
        {
          path: 'map/query',
          component: PublicMapQueryPage,
          name: 'publicMapQuery'
        },
      ]
    },
    { 
      path: '/support',
      name: 'support',
      component: Support
    },
    { 
      path: '/about',
      name: 'about',
      component: About
    },
    { 
      path: '/contact',
      name: 'contact',
      component: Contact
    },
    { 
      path: '/dashboard',
      component: DashboardContainer,
      beforeEnter: requireAuth,
      children: [
        { path: '', name: 'dashboard', component: Dashboard },
        { path: 'account', name: 'account', component: Profile, props: true },
        { path: 'profile', redirect: { name: 'account' } },
        // { path: 'help', name: 'dashboardHelp', component: Help },
        { path: 'helpers', name: 'helpers', component: Helpers },
        { path: 'helpers/add', name: 'helpersAdd', component: HelpersAdd },
        { path: 'helpers/:id/edit', name: 'helpersEdit', component: HelpersEdit },
        {
          path: 'reports',
          component: ReportsContainer,
          children: [
            { path: '', name: 'reports', component: Reports },
            { path: 'build/:rid?', name: 'reportsBuilder', component: ReportsBuilder, props: true }
          ]
        },
        { path: 'export', name: 'export', component: Export },
        { path: 'gauge', redirect: '/dashboard' },
        { path: 'gauge/add', component: GaugeAdd, name: 'addGauge'},
        { 
          path: 'gauge/:gid',
          component: Gauge,
          children: [
            { path: '', component: GaugeDetail, name: 'gaugeDetail'},
            { path: 'add/success', component: GaugeAddSuccess, name: 'gaugeAddSuccess' },
            { path: 'empty', component: GaugeEmpty, name: 'gaugeEmpty' },
            { path: 'edit', component: GaugeEdit, name: 'editGauge' },
            // { path: 'report', redirect: { name: 'reportsBuilder' } },
            { path: 'report', name: 'gaugeReport', redirect: to => {
                const {
                  params: {
                    gid
                  } = {}
                } = to

                return {
                  name: 'reportsBuilder',
                  query: {
                    gids: gid
                  }
                }
              } 
            },
            { path: 'log/add', component: LogAdd, name: 'addLog' },
            { path: 'log/multi-add', component: LogMultiAdd, name: 'addMultiLog' },
            { path: 'log/add/success', component: LogAddSuccess, name: 'addLogSuccess' }
          ]
        },
      ]
    },
    { 
      path: '/login',
      name: 'login',
      component: Login,
      props: (route) => ({ user: route.query.user })
    },
    { 
      path: '/logout',
      name: 'logout',
      beforeEnter(to, from, next) {
        store.dispatch('logout')
          .then(() => next('/'))
      }
    },
    { 
      path: '/signup',
      name: 'signup',
      component: SignUp
    },
    { path: '/confirm', name: 'Confirm', component: Confirm, props: (route) => ({ 
        confirmId: route.query.user,
        confirmCode: route.query.code
      }) 
    },
    { path: '/confirm-email', name: 'ConfirmEmail', component: ConfirmEmail, props: (route) => ({ 
        confirmId: route.query.user, 
        confirmEmail: route.query.email, 
        confirmCode: route.query.code
      }) 
    },
    { 
      path: '/forgot-password',
      name: 'forgotPassword', 
      component: ForgotPass
    },
    { 
      path: '/change-password',
      name: 'changePassword',
      component: ChangePassword,
      props: (route) => ({ 
        code: route.query.code, 
        email: route.query.email,
      })
    },
    { 
      path: '/terms',
      name: 'terms',
      component: Terms
    },
    { 
      path: '/privacy',
      name: 'privacy',
      component: Privacy
    },
    { path: '*', redirect: '/' }
  ]
})

router.beforeEach((to, from, next) => {
  const { name } = to

  document.title = (name && PAGE_TITLES[name]) ? PAGE_TITLES[name] : PAGE_TITLE_SUFFIX

  next()
})

export default router
