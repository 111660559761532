<template>
  <v-app id="app" class="app" :class="classObject">
    <dialog-manager />
    
    <main-header v-if="showAppWrapper"/>

    <transition name="fade" mode="out-in">
      <sticky v-if="showDateRange" :key="$route.name">
        <div class="date-range__wrapper">
          <date-range class="app__date-range" />
        </div>
      </sticky>
    </transition>

    <main class="app__main">
      <transition name="fade" mode="out-in">
        <!-- route outlet -->
        <!-- component matched by the route will render here -->
        <router-view></router-view>
      </transition>
    </main>

    <main-footer v-if="showAppWrapper"/>
    <refresh-ui></refresh-ui>
  </v-app>
</template>

<script>
import DialogManager from '@/components/_managers/DialogManager'
import Footer from '@/components/Footer/Footer'
import Header from '@/components/Header/Header'
import DateRange from '@/components/DateRange/DateRange'
import RefreshUi from '@/components/RefreshUi/RefreshUi'
import getOnlineStatus from '@/utils/onLinePolyfill'
import DisplayManagerMixin from '@/mixins/DisplayManagerMixin'

export default {
  name: 'app',
  
  components: {
    DialogManager,
    'main-footer': Footer,
    'main-header': Header,
    DateRange,
    RefreshUi
  },
  
  mixins: [DisplayManagerMixin],
  
  computed: {
    classObject() {
      return {
        'app': true,
        'app--offline-bar': this.showOfflineBar,
        'app--modal-open': Boolean(this.isModalOpen)
      }
    }
  },
  
  created () {
    if (process.env.NODE_ENV !== 'production') {
      console.info("checking for on/offline status...")
    }
    // https://davidwalsh.name/detecting-online
    // possible polyfill: https://github.com/remy/polyfills/blob/master/offline-events.js
    // good open source example: https://github.com/HubSpot/offline
    // (http://caniuse.com/#feat=online-status)

    const {dispatch} = this.$store

    // Update the online status based on connectivity
    // comment out these two lines to completely disable the offline feature
    window.addEventListener('online', () => { dispatch('updateOffline', false) })
    window.addEventListener('offline', () => { dispatch('updateOffline', true) })

    getOnlineStatus().then(status => { dispatch('updateOffline', !status) })
    // dispatch('updateOffline', !getOnlineStatus())

    dispatch('checkAndGetUserAuth').then(() => {}).catch(() => {
      // do nothing
    }) 
  },
  
  mounted() {
    if(!(typeof(window.componentHandler) == 'undefined') && this.$refs.loader !== undefined) {
      window.componentHandler.upgradeElement(this.$refs.loader);
    }
  },
  
  updated() {
    if(!(typeof(window.componentHandler) == 'undefined') && this.$refs.loader !== undefined) {
      window.componentHandler.upgradeElement(this.$refs.loader);
    }
  }
}
</script>

<style lang="css">
@import "leaflet/dist/leaflet.css"
</style>
