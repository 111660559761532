<template>
  <div class="gauge-add-map">
    <div class="gauge-add-map__inner">
      <l-map
        :map-id="mapId"
        :events="['ready', 'dblclick']"
        :options="{
          center: [38.79, -98.53],
          zoom: 4
        }"
        v-on:ready="handleMapLoad"
        v-on:dblclick="handleMapDoubleClick">
        <l-tile-layer v-bind="tileLayer"/>

         <l-marker 
          v-if="showMarker"
          :events="['dragend']"
          :options="{ draggable: true, icon }" 
          :latlng="mx_value"
          v-on:dragend="handleMarkerDragEnd"/>
      </l-map>

      <div :class="getActionClasses">
        <v-btn 
          class="gauge-add-map__find-me"
          color="accent"
          depressed
          rounded
          @click.prevent="geoFindMe"
          small>
          {{ btnFindMeText || 'Find my location' }}
        </v-btn>
        <v-btn 
          class="gauge-add-map__find-myself white--text bare"
          v-if="showActions"
          text
          @click.prevent="showActions = false"
          small>
          I'll find it myself
        </v-btn>
      </div>
    </div>
    
    <div class="gauge-add-map__error" v-if="locationError">
      Could not get your location, please check your browser settings.
    </div>
  </div>
</template>

<script>
  import Leaflet from 'leaflet'
  import LMap from '@vue-mapp-kit/leaflet/lib/LMap/LMap.vue'
  import LMarker from '@vue-mapp-kit/leaflet/lib/ui/LMarker/LMarker.vue'
  import LTileLayer from '@vue-mapp-kit/leaflet/lib/raster/LTileLayer/LTileLayer.vue'
  import ExternalBind from '@/mixins/ExternalBind'
  import { mapGetters } from 'vuex'
  import { myGaugeIcon, commonTileLayer } from '@/utils/leafletUtils'

  let GaugeAddMap = {
    name: 'gauge-add-map',

    mixins: [ExternalBind],

    components: {
      LMap,
      LMarker,
      LTileLayer
    },

    watch: {
      // if Leaflet.latLng throws an error, then it keeps old value
      // value comes from ExternalBind mixin
      value(newValue, oldValue) {
        try {
          Leaflet.latLng(newValue[0], newValue[1])

          this.mx_value = newValue
        } catch(e) {
          this.mx_value = oldValue
        }
      }
    },

    created () {
      // non-reactive
      this.leafletMap = null
    },

    data() {
      return {
        mapId: 'mainMap',
        icon: myGaugeIcon(),
        startingPosition: [32.287132632616384, -110.89599609375001],
        btnFindMeText: '',
        showActions: true,
        locationError: false,
        tileLayer: commonTileLayer(),
      }
    },
    computed: {
      ...mapGetters(['getMap']),
      getActionClasses() {
        return {
          'gauge-add-map__geo-locate': true,
          'gauge-add-map__geo-locate--show-actions': !this.showMarker && this.showActions,
        }
      },
      showMarker() {
        try {
          Leaflet.latLng(this.mx_value[0], this.mx_value[1])

          const bool = (
            Array.isArray(this.mx_value)
            && !!(this.mx_value[0] && this.mx_value[1])
          )

           if (bool) this.centerMap(this.mx_value)

          return bool
        } catch(e) {
          return false
        }
      }
    },
    methods: {
      centerMap(position) {
        const m = this.leafletMap

        if (m) m.setView(position)
      },
      handleMapLoad(map) {
        this.leafletMap = map
        this.leafletMap.doubleClickZoom.disable()
      },
      handleMapDoubleClick({ event }) {
        let {
          latlng: {
            lat,
            lng
          } = {}
        } = event

        // sending latlng object back to GaugeAdd
        // see ExternalBid for this.$emit('input', [lat, lng])
        this.mx_value = [lat, lng]
      },
      handleMarkerDragEnd({ module }) {
        let {
          _latlng: {
            lat,
            lng
          } = {}
        } = module

        // sending latlng object back to GaugeAdd
        // see ExternalBid for this.$emit('input', [lat, lng])
        this.mx_value = [lat, lng]
      },
      geoFindMe() {
        let setPosition = (latlng) => {
          this.btnFindMeText = ''
          this.getMap().fitBounds([latlng])

          this.$emit('input', latlng)
          this.mx_value = latlng
        }

        let success = (position) => {
          let {latitude, longitude}  = position.coords;
          setPosition([latitude, longitude])
        }

        let error = (e) => {
          this.locationError = true
          this.btnFindMeText = ''
        }

        this.btnFindMeText = "Locating..."
        navigator.geolocation.getCurrentPosition(success, error);
      },
    }
  }

  export default GaugeAddMap
</script>

<style lang="sass" src="./GaugeAddMap.sass"></style>
