import api, { mapGaugeLog } from '@/api/gaugeLogs'
import offlineApi from '@/api/localstorage'
import store from '../index.js'
import {
  RECEIVE_LOGS,
  ADD_LOG,
  UPDATE_LOG,
  DELETE_LOG,
  ADD_LOG_GAUGE,
  ADD_LOG_FROM_QUEUE,
  DATE_RANGE_UPDATE,
  DELETE_GAUGE,
  MASSAGE_LOGS,
  USER_LOGOUT,
  UI_ADD_LOADING_STATE,
  UI_REMOVE_LOADING_STATE
} from '../mutation-types'
import { decimalDoublePad } from '@/utils/numUtils'
import {
  dateToLocal,
  dateToDay,
  isBetween,
  rccDateFormat
} from '@/utils/dateUtils'
import { Parser } from 'json2csv'
import {
  getEstimatedDataByDate,
  getHistoricalDataByDate,
  getSpiLabel,
  sortLogsByDate,
  cumulateLogs,
  cumulateLogsWithModifier
} from '@/utils/backendShared'

/**
  log shape from api: {
    created: "20170629",
    gid: "92b58000-513e-11e7-a405-2712b7571f66",
    isReset: true,
    lid: 1498758874,
    logDateTime: "2017-06-29T17:54:34Z",
    reading: "0.0000",
    units: "in"
  }
*/

// initial state
const state = {
  all: [], //array of log objects
  gauges: [], //just a list of string gids
}

// getters
const getters = {
  allLogs: state => state.all,
  
  countLogs: state => state.all.length,
  
  logIndex: (state, getters) => (lid) => {
    return state.all.findIndex(log => log.lid === lid)
  },
  // return all logs unless gid is passed
  
  getLogList: (state, getters) => (gid) => {
    return (gid) 
      ? state.all.filter(log => log.gid === gid)
      : state.all
  },

  getLogCount: (state, getters) => (gid) => {
    return getters.getLogList(gid).length
  },
  
  getOldestLog: (state, getters) => (gid) => {
    let logs = getters.getLogList(gid)

    return logs[logs.length - 1] || {}
  },
  
  getInvertedLogList: (state, getters) => (gid) => {
    return (gid)
      ? state.all.filter(log => log.gid !== gid)
      : state.all
  },
  // when modules/dateRange {from, to} updates, this getter refreshes
  getLogListByDateRange: (state, getters) => (gid, from, to) => {
    const f = from || getters.from
    const t = to || getters.to

    return getters.getLogList(gid).filter(log => {
      const logDateTime = dateToLocal(log.logDateTime)

      // return logDateTime >= f && logDateTime <= t
      return isBetween(logDateTime, f, t)
    }) || []
  },
  
  getOldestLogWithinDateRange: (state, getters) => (gid) => {
    let logs = getters.getLogListByDateRange(gid)

    return logs[logs.length - 1] || {}
  },

  getLogDataAsXY: (state) => (logs) => {
    // creates array of {x: logdate, y: cumulativeRainfall} coordinates objects 
    return logs.reduce((accum, log) => {
      return accum.concat({
        x: dateToLocal(log.logDateTime),
        y: log.cumulative
      })
    }, [])
  },

  getLogDatesAsArray: (state, getters) => (logs, gid) => {
    const innerLogs = logs || getters.getLogListByDateRange(gid)
    // creates array of date strings based on first and last log in this.gaugeLogs
    return innerLogs.reduce((accum, log) => {
      accum.push(log.logDateTime)
      return accum
    }, [])
  },

  getTotalCumulation: (state, getters) => ({ gid, logs = [], from, to }) => {
    const modifiedLogData = getters.cumulateLogs({
      gid,
      logs,
      from,
      to
    })

    return modifiedLogData.length
      ? decimalDoublePad(modifiedLogData[modifiedLogData.length - 1].cumulative)
      : 0
  },

  getTotalCumulationWithModifier: (state, getters) => ({ gid, logs = [], from, to }) => {
    const modifiedLogData = getters.cumulateLogsWithModifier({
      gid,
      logs,
      from,
      to
    })

    return modifiedLogData.length
      ? decimalDoublePad(modifiedLogData[modifiedLogData.length - 1].cumulative)
      : 0
  },

  sortLogs: (state) => sortLogsByDate,

  cumulateLogs: (state, getters) => ({ gid, logs = [], from, to, modifier = 0, sort = true }) => {
    const logsToUse = gid
      ? getters.getLogListByDateRange(gid, from, to)
      : logs

    return cumulateLogs({ logs: logsToUse, modifier, sort })
  },

  cumulateLogsWithModifier: (state, getters) => ({ gid, logs = [], from, to }) => {
    const logsToUse = getters.sortLogs(gid ? getters.getLogList(gid) : logs, 'asc')
    const start = from || getters.from
    const end = to || getters.to

    return cumulateLogsWithModifier({ logs: logsToUse, from: start, to: end })
  }
}

const actions = {
  /**
   * Fetching ALL GAUGE LOGS for user's gauges and user's shared gauges
   * Not fetching for archived
   * Creating a promise.all and using getGaugeLogs in a loop
   */
  async fetchAllLogs ({ dispatch, commit, getters }) {
    try {
      commit(UI_ADD_LOADING_STATE, 'allLogsLoading')
      // is not fetching logs for archived gauges
      const gauges = getters.getAllGauges

      await Promise.all(gauges.map(gauge => { return dispatch('getGaugeLogs', gauge.gid) }))

      return
    } finally {
      commit(UI_REMOVE_LOADING_STATE, 'allLogsLoading')
    }
  },

  /**
   * Action for fetching logs for a single gauge
   */
  async getGaugeLogs ({ commit }, gid) {
    // gets logs from localStorage by gaugeId
    // function getOfflineLogs(gid) {
    //   return new Promise((resolve, reject) => {
    //     let response = offlineApi.logs.getByGauge(gid)
    //     resolve(response)
    //   })
    // }

    // check if gauge logs have already been fetched
    if (state.gauges.includes(gid)) {
      console.log('gauge logs already fetched...')
      return state.all.filter(log => { return log.gid === gid })
    }

    // either scenario returns a promise, so .then() can be used
    // you could handle individual .then() right in the terneray
    const response = (store.getters.isOffline)
      ? offlineApi.logs.getByGauge(gid)
      : await api.getLogs(gid)

    commit(RECEIVE_LOGS, response)
    commit(MASSAGE_LOGS, gid)
    commit(ADD_LOG_GAUGE, gid)

    return response
  },

  addGaugeLog({commit, dispatch}, payload) {
    // @todo could sest userId, helperUid, helperName here instead of at the component level
    return (store.getters.isOffline || store.getters.localSyncRunning)
      ? dispatch('addGaugeLogByQueue', payload)
      : dispatch('addGaugeLogByApi', payload)
  },

  // adds log to queue local storage and then to Vuex store
  addGaugeLogByQueue({commit, dispatch}, payload) {
    const queueId = payload.queueId || null

    // queueItem being set to the id or the {} determine if it will be a setter or a getter
    let queueItem = queueId || {
      type: 'addGaugeLog',
      data: {
        label: 'Add Log',
        date: dateToDay(payload.logDateTime),
        meta: [
          { label: 'Reading', value: payload.reading },
          { label: 'Gauge', value: store.getters.getGaugeById(payload.gid).title }
        ]
      },
      payload: { ...payload }  // this is the payload from form submission
    }

    // 'getSetQueueItem' is a setter/getter so this can be re-used for local syncing
    let response = dispatch('getSetQueueItem', queueItem).then(response => {
      // massages the data to have a similar response to the actual API
      let mappedPayload = mapGaugeLog({...response.payload})
      return {...mappedPayload, toBeSynced: true, queueId: response.queueId}
    })

    return response.then(response => {
      // queueId is set at the top of this function / this is the flag that says:
      // "Hey, I'm being dispatched from the queue"

      // helperName, helperUid not return from log POST, spreading them to response to keep in store
      let { helperUid, helperName } = payload
      const modResponse = (helperUid) ? { ...response, helperUid, helperName } : response

      commit(ADD_LOG, modResponse)

      dispatch('massageLogs', response.gid)
      return response
    })
  },

  // adds log to live database and then response to Vuex store
  // if log contains a queueId then call different type of commit
  addGaugeLogByApi({commit, dispatch}, payload) {
    const queueId = payload.queueId || null
    let response = api.addLog(payload).then(response => { return response })

    return response.then(response => {
      // queueId is set at the top of this function / this is the flag that says:
      // "Hey, I'm being dispatched from the queue"

      // helperName, helperUid not return from log POST, spreading them to response to keep in store
      let { helperUid, helperName } = payload
      const modResponse = (helperUid) ? { ...response, helperUid, helperName } : response

      ;(queueId)
        ? commit(ADD_LOG_FROM_QUEUE, { ...modResponse, queueId })
        : commit(ADD_LOG, modResponse)
      
      dispatch('massageLogs', response.gid)
      return response
    }) 
  },

  updateLog({ commit }, payload) {
    log("updateLog: inpect payload", payload)
    let {lid, image} = payload

    return api.updateLog({...payload}).then(response => {
      const putData = {...response, lid, image}
      commit(UPDATE_LOG, putData)
    })
  },

  deleteLog({ commit }, payload) {
    log("deleting gaugeLog", payload)
    let lid = payload.lid

    return api.deleteLog(payload).then(response => {
      commit(DELETE_LOG, lid)
    })
  },

  /**
   * @name convertToCsv
   * @description convert gauge logs to csv based on config parameters
   * @param {Object} store vuex store object
   * @param {String} params.gid gauge id to export logs 
   * @param {Object} params.config see Export data.form for options
   * @returns {String} csv string
   */
  async convertToCsv ({ dispatch, getters }, { gid, config }) {
    const {
      from,
      to,
      format,
      additional
    } = config

    const logs = (from && to)
      ? getters.getLogListByDateRange(gid, from, to)
      : getters.getLogList(gid)
    const longLat = getters.getGaugeLngLatString(gid)
    const innerFrom = from || logs[logs.length - 1].logDateTime
    const innerTo = to || logs[0].logDateTime

    const historicalData = additional.includes('spi')
      ? await dispatch('getHistoricalData', {
          gid,
          loc: longLat,
          sdate: rccDateFormat(innerFrom),
          edate: rccDateFormat(innerTo)
        })
      : []

    const estimatedData = additional.includes('estimated')
      ? await dispatch('getTheoreticaldata', {
          gid,
          loc: longLat,
          sdate: rccDateFormat(innerFrom),
          edate: rccDateFormat(innerTo)
        })
      : []

    const formatted = logs.slice().reverse().reduce((outcome, log) => {
      const {
        gid,
        lid,
        units,
        created,
        isReset,
        logDateTime,
        reading,
        record,
        cumulative,
        notes,
        image
      } = log

      if (!format.includes('raw') && isReset) {
        return outcome
      }

      const quantileByDate = getHistoricalDataByDate(logDateTime, historicalData)
      const estimatedValue = additional.includes('estimated')
        ? getEstimatedDataByDate(logDateTime, estimatedData).y
        : ''
      const cumulativeSpiLabel = additional.includes('spi')
        ? getSpiLabel(cumulative, quantileByDate)
        : ''
      const estimatedSpiLabel = additional.includes('spi')
        ? getSpiLabel(estimatedValue, quantileByDate)
        : ''

      return outcome.concat({
        'Gauge ID': gid,
        'Log Id': lid,
        'Observed Date': dateToLocal(logDateTime),
        // 'Created': created,
        'Units': units,
        'Is Reset?': isReset,
        ...(format.includes('raw') ? { 'Reading': reading } : {}),
        ...(format.includes('difference') ? { 'Actual Precip. (difference)': isReset ? '' : decimalDoublePad(record) } : {}),
        // @todo blanks cells? Ask Mike and Hannah?
        ...(format.includes('accumulation') ? { 'Running Total': isReset ? '' : decimalDoublePad(cumulative) } : { 'Running Total': '' }),
        ...(format.includes('accumulation') && cumulativeSpiLabel ? { 'Running Total SPI Label': isReset ? '' : cumulativeSpiLabel } : {}),
        ...(additional.includes('estimated') ? { 'Estimated (ACIS Grid)': isReset ? '' : decimalDoublePad(estimatedValue) } : {}),
        ...(
          additional.includes('estimated') && estimatedSpiLabel
            ? { 'Estimated SPI Label': isReset ? '' : estimatedSpiLabel }
            : {}
        ),
        ...(additional.includes('notes') ? { 'Notes': notes } : {}),
        ...(additional.includes('location') ? { 'Gauge Lng/Lat': longLat } : {}),
        ...(additional.includes('photos') ? { 'Image URL': image } : {}),
      })
    }, [])
    const parser = new Parser({
      fields: Object.keys(formatted[0])
    })
    return parser.parse(formatted)
  },

  massageLogs({ commit }, gid) {
    commit(MASSAGE_LOGS, gid)
  }
}


// mutations
const mutations = {
  [RECEIVE_LOGS] (state, gaugeLogs) {
    // info("RECEIVE_LOGS", gaugeLogs)

    // TODO: this should be a little more of an intelligent merge
    state.all = state.all.concat(gaugeLogs.slice())
  },
  [ADD_LOG] (state, gaugeLog) {
    // separate logs by gid and ALL OTHER LOGS
    let logs = state.all.slice()
    logs.push(gaugeLog)

    state.all = logs
  },
  [ADD_LOG_FROM_QUEUE] (state, gaugeLog) {
    // find log by lid/index and update it with updatedLog
    let logs = state.all.slice()
    const index = logs.findIndex(log => log.queueId === gaugeLog.queueId)

    if (!!~index) {
      // use spread operator to create copy of object to avoid mutability
      state.all[index] = {...logs[index], ...gaugeLog, toBeSynced: false}
    } else {
      logs.push(gaugeLog)
      state.all = logs
    }
  },
  [UPDATE_LOG] (state, updatedLog) {
    // find log by lid/index and update it with updatedLog
    const index = store.getters.logIndex(updatedLog.lid)

    // use spread operator to create copy of object to avoid mutability
    state.all[index] = {...state.all[index], ...updatedLog}
  },
  [DELETE_LOG] (state, lid) {
    // make copy of all gauge and get the index using lid
    const logs = state.all.slice()
    logs.splice(store.getters.logIndex(lid), 1)

    state.all = logs
  },
  [MASSAGE_LOGS] (state, gid) {
    // based on gid this mutation will append records and cumulative data and re-sort
    let {
      getters: {
        getLogList,
        getInvertedLogList,
        sortLogs,
        cumulateLogs
      }
    } = store

    let logsByGid = getLogList(gid)
    let allOtherLogs = getInvertedLogList(gid)
    const newLogData = cumulateLogs({ logs: logsByGid })

    state.all = sortLogs(allOtherLogs.concat(newLogData))
  },
  [ADD_LOG_GAUGE](state, gid) {
    state.gauges.push(gid)
  },
  [DATE_RANGE_UPDATE](state, payload) {
    state = Object.assign(state, payload)
  },
  [DELETE_GAUGE] (state, gid) {
    let {
      getters: { getInvertedLogList, sortLogs }
    } = store

    // update state.all logs to be all the logs that DO NOT contain gid
    let allOtherLogs = getInvertedLogList(gid)

    state.all = sortLogs(allOtherLogs)
  },
  [USER_LOGOUT] (state, payload) {
    state.all = []
    state.gauges = []
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
