import { merge } from 'lodash'
import { dateToDay } from '@/utils/dateUtils'
import moment from 'moment'
import numbro from 'numbro'
import uuid from 'uuid/v4'

export const baseLineColor = 'rgb(0,0,0)'
export const tertiaryColors = [
  '#1F78B4',
  '#B981EA',
  '#E31A1C',
  '#8CCE4D',
  '#FFFF99',
  '#FF7F00',
  '#82B7CE',
  '#33A02C',
  '#FB9A99',
  '#EDA743 '
]

export const quantileColor = (val = '', opacity = 1) => {
  const lookup = {
    q1: `rgba(194,152,104,${opacity})`, // #C29868,
    q2: `rgba(210,179,146,${opacity})`, // #D2B392,
    q3: `rgba(224,207,190,${opacity})`, // #E0CFBE,
    q4: `rgba(175,195,155,${opacity})`, // #AFC39B,
    q5: `rgba(145,175,117,${opacity})` // #91AF75
  }

  return lookup[val.toLowerCase()]
}

export const getDatasetValueByDate = (data, match) => {
  return data.find((day) => {
    return dateToDay(day.x) === dateToDay(match)
  })
}

export const defaultLineOptions = (options = {}) => {
  return merge(
    {
      events: ['click'],
      tooltips: {
        intersect: false,
        displayColors: false,
        callbacks: {
          // converting tooltip title to human readable date
          beforeTitle: (tooltipItem, chart) => {
            const { datasets = [] } = chart
            const [{
              datasetIndex,
              xLabel = 'Data Point'
            }] = tooltipItem
            const {
              label: title,
              mrlLegend
            } = datasets[datasetIndex]
            const titleSplitYear = title.split(' ')[0]
            const formattedLabel = title.toLowerCase().includes('ago') && titleSplitYear && !isNaN(Number(titleSplitYear))
              ? moment(xLabel).subtract(Number(titleSplitYear), 'years').format()
              : xLabel

            return mrlLegend === 'historical'
              ? moment(formattedLabel).format('MM/DD')
              : dateToDay(formattedLabel)
          },
          title: (tooltipItem, chart) => {
            const { datasets = [] } = chart
            const { datasetIndex } = tooltipItem[0]

            return datasets[datasetIndex].label === 'Dry'
              ? `${datasets[datasetIndex].label} (median)`
              : datasets[datasetIndex].label
          },
          // beforeBody: (tooltipItem, chart) => {
          //   const { datasets = [] } = chart
          //   const { datasetIndex, index } = tooltipItem[0]

          //   const {
          //     data = []
          //   } = datasets[datasetIndex]

          //   return index > 0
          //     ? `Difference: ${numbro(data[index].y - data[index - 1].y).format('0.00')}`
          //     : ''
          // },
          // beforeLabel: (tooltipItem, chart) => {
          //   const { datasets = [] } = chart
          //   const { datasetIndex, index } = tooltipItem

          //   return index > 0
          //     ? `Reading: ${numbro(datasets[datasetIndex].data[index].reading).format('0.00')}`
          //     : ''
          // },
          // making sure value is formatted as two decimal places
          label: (tooltipItem, chart) => {
            const { index, yLabel = 0.00 } = tooltipItem

            return index === 0 ? false : `Cumulative: ${numbro(yLabel).format('0.00')}`
          },
          afterLabel: (tooltipItem, chart) => {
            const { datasets = [] } = chart
            const { datasetIndex, xLabel } = tooltipItem
            const {
              estimatedLookup
            } = datasets[datasetIndex]
            const estimatedDataset = datasets.find(d => d.label === estimatedLookup)

            if (!estimatedDataset) { return '' }

            const yLabel = getDatasetValueByDate(estimatedDataset.data, xLabel).y

            return `Estimated cumulative: ${numbro(yLabel).format('0.00')}`
          }
        }
      },

      maintainAspectRatio: false,
      elements: {
        point: {
          radius: 0,
          hitRadius: 0,
          hoverRadius: 0
        }
      },
      scales: {
        xAxes: [{
          type: 'time',
          time: {
            displayFormats: {
              quarter: 'MM/DD/YY',
              year: 'MM/DD/YY',
              month: 'MM/DD/YY',
              week: 'MM/DD/YY',
              day: 'MM/DD/YY'
            }
          },
          gridLines: {
            z: 1,
            color: 'rgba(0, 0, 0, 0.05)'
          }
        }],
        yAxes: [{
          position: 'right',
          gridLines: {
            z: 1,
            color: 'rgba(0, 0, 0, 0.05)'
          }
        }]
      }
    },
    options
  )
}

export const createReportChartObject = (options = {}) => {
  return merge({
    id: uuid(),
    hash: {
      gid: '',
      uid: ''
    },
    comparison: {
      type: 'year',
      values: []
    },
    chartConfig: {
      legend: {
        baseObservation: {
          visible: true,
          labels: []
        },
        baseEstimate: {
          visible: true,
          labels: []
        },
        historical: {
          visible: true,
          labels: []
        },
        comparisonObservation: {
          visible: true,
          labels: []
        },
        comparisonEstimated: {
          visible: true,
          labels: []
        }
      }
    }
  }, options)
}

export const observationDatasetObject = (options = {}) => {
  return merge({
    fill: false,
    data: [],
    label: 'Observation Rainfall',
    borderColor: baseLineColor,
    pointRadius: 5,
    pointHoverRadius: 8,
    lineTension: 0,
    order: 0,
    hidden: false
  }, options)
}

export const estimatedDatasetObject = (options = {}) => {
  return merge({
    fill: false,
    data: [],
    label: 'Estimated Rainfall',
    borderColor: baseLineColor,
    lineTension: 0,
    borderDash: [5, 5],
    order: 0,
    hidden: false
  }, options)
}
