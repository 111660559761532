/**
* Charts API
* using axios https://github.com/mzabriskie/axios
*/
import axios from 'axios'
import { instance } from './utils/authRequest'
import {
  massageTheoreticalData,
  massageHistoricalData
} from '@/utils/backendShared'

const chartRequest = axios.create({
  baseURL: "https://data.rcc-acis.org",
  timeout: 1000 * 60 * 5, // 5 min
  headers: {
    'Content-Type' : 'application/json', 
    'Accept' : 'application/json'
  },
});

const noAuthRequest = instance()

export default {
  // DEPRECATED
  getAcisGridData({loc, sdate, edate, grid = '21', elems}) {
    if (!loc || !sdate || !edate || !elems) throw new Error('Missing correct params in getMonthlyData api call')

    return chartRequest.post('/GridData', { loc, sdate, edate, grid, elems }).then((response) => {
      let { data = [] } = response.data

      return data
    })

    // return axios.get('https://httpbin.org/status/504').then(reponse => response)
  },

  // TODO: add timers or check chrome profiling
  async getHistoricalData({loc, sdate, edate}) {
    if (!loc || !sdate || !edate) throw new Error('Missing correct params in getHistoricalData api call')

    const response = await noAuthRequest({
      url: '/rcc-acis/historical-data',
      method: 'post',
      data: { loc, sdate, edate, asQuantiles: true }
    })
    
    return massageHistoricalData(response.data)
  },

  async getTheoreticalData({ loc, sdate, edate }) {
    if (!loc || !sdate || !edate) throw new Error('Missing correct params in getTheoreticalData api call')
    
    // get theoretical data and then passes it to massage util function
    const response = await noAuthRequest({
      url: '/rcc-acis/estimated-data',
      method: 'post',
      data: { loc, sdate, edate }
    })

    return massageTheoreticalData(response.data)
  }
}
