<template>
  <v-container
    fluid
    grid-list-lg
    id="results"
    class="PublicMapQueryPage pt-0"
  >
    <page-section theme="blue" centered :loading="publicGaugesLoading">
      <h1>If you build it. The data will come.</h1>
    </page-section>

    <page-section>
      <v-row>
        <v-col cols="8">
          <div class="PublicMapQueryPage__wrapper">
            <public-map
              hide-layers-control
              v-lock-on-scroll
            />

            <div
              v-if="showActions"
              class="PublicMapQueryPage__action-overlay"
            >
              <p class="white--text font-weight-bold text-center pa-6 pb-0">
                Move the extents of the map over the desired gauges you'd like to query. Fill out the fields on the right and submit the form. Your results will appear below the map.
              </p>
              <v-btn 
                color="accent"
                depressed
                rounded
                large
                @click="showActions = false"
              >
                Got it
              </v-btn>
            </div>
          </div>
        </v-col>

        <v-col cols="4">
          <div class="PublicMapQueryPage_form">
            <form
              @submit.prevent="formSubmit(submit)"
              class="form"
              novalidate
            >
              <v-select 
                v-model="queryType"
                outlined
                label="Query Type"
                class="white mb-3"
                dense
                hide-details
                :items="[
                  'Gauges',
                  'Gauges and Logs'
                ]"
              />

              <checkbox 
                v-if="queryType === 'Gauges and Logs'"
                v-model="form.includeReport"
                label="Include quantile report data"
                name="includeReport"
                class="ml-6 mb-4"
              />
  
              <div class="mb-3">
                <date-menu
                  v-model="form.logDateStart"
                  name="from"
                  label="Log Date From"
                  hide-actions
                  dense
                  :return-format="datePicker"
                  :picker-props="{
                    max: datePicker(new Date())
                  }"
                  :text-field-props="{
                    hideDetails: true,
                    singleLine: false,
                    appendIcon: ''
                  }"
                />
              </div>
  
              <div class="mb-3">
                <date-menu
                  v-model="form.logDateEnd"
                  name="to"
                  label="Log Date To"
                  hide-actions
                  dense
                  :return-format="datePicker"
                  :picker-props="{
                    max: datePicker(new Date())
                  }"
                  :text-field-props="{
                    hideDetails: true,
                    singleLine: false,
                    appendIcon: ''
                  }"
                />
              </div>
  
              <v-textarea
                :value="bounds"
                v-validate="'required'"
                :error-messages="errors.collect('bounds')"
                data-vv-name="bounds"
                name="bounds"
                label="Bounds (extent)"
                rows="4"
                hint="Read only"
                outlined
                readonly
              />
  
              <v-btn
                type="submit" 
                color="accent"
                depressed
                rounded
                large
                block
                class="form__submit mt-3"
                :loading="loading"
              >
                Submit
              </v-btn>
            </form>
          </div>
        </v-col>
      </v-row>
    </page-section>

    <page-section
      class="PublicMapQueryPage__results"
    >
      <h2>
        Results
      </h2>

      <h4>URL</h4>
      <div class="PublicMapQueryPage__copy-section mb-6">
        <pre>{{ generatedUrl }}</pre>
        <v-btn
          color="gray"
          rounded
          depressed
          small
          @click="copyUrl"
          class="PublicMapQueryPage__copy-section__btn"
        >
          Copy
          <v-icon small right>
            {{ copyUrlSuccess ? 'mdi-check' : 'mdi-content-copy '}}
          </v-icon>
        </v-btn>
      </div>

      <h4>Response</h4>
      <div class="PublicMapQueryPage__copy-section">
        <pre>{{ results }}</pre>
        <v-btn
          color="gray"
          rounded
          depressed
          small
          @click="copyResults"
          class="PublicMapQueryPage__copy-section__btn"
        >
          Copy
          <v-icon small right>
            {{ copyResultsSuccess ? 'mdi-check' : 'mdi-content-copy '}}
          </v-icon>
        </v-btn>
      </div>
    </page-section>
  </v-container>
</template>

<script>
  import copy from 'copy-to-clipboard'
  import MappKitBus from '@vue-mapp-kit/leaflet/lib/buses'
  import PublicMap from '@/components/PublicMap/PublicMap'
  import DisplayManagerMixin from '@/mixins/DisplayManagerMixin'
  import FormValidation from '@/mixins/FormValidation'
  
  import AwsConfig from '@/api/lib/aws/config'
  import authRequest from '@/api/utils/authRequest'
  import {
    datePicker
  } from '@/utils/dateUtils'
  import { mapGetters } from 'vuex'

  let PublicMapQueryPage = {
    components: {
      PublicMap
    },

    mixins: [
      DisplayManagerMixin,
      FormValidation
    ],

    data () {
      return {
        datePicker,
        showActions: true,
        title: "Public Map Query",
        tagline: "A simple utility to help build public queries against the public gauges/gauge logs in the MyRAINge Log system.",
        msg: '',
        queryType: 'Gauges',
        copyUrlSuccess: false,
        copyResultsSuccess: false,
        form: {
          from: 0,
          size: 1000,
          coordinates: null,
          bounds: null,
          logDateStart: this.from,
          logDateEnd: this.to,
          includeReport: false
        },
        results: {}
      }
    },

    created () {
      MappKitBus.$on('mainMap-map-moveend', this.setBounds)
    },

    beforeDestroy () {
      MappKitBus.$off('mainMap-map-moveend', this.setBounds)
    },

    computed: {
      ...mapGetters({
        from: 'from',
        to: 'to'
      }),
      /**
       * Loop through this.form and convert values into key=value
       * string pairs to build a query params string
       */
      urlParams () {
        let params = []

        for (let key in this.form) {
          if (this.form[key] !== '' && this.form[key] !== null) {
            if (Array.isArray(this.form[key]) || typeof this.form[key] === 'object') {
              params.push(
                `${key}=${JSON.stringify(this.form[key])}`
              )
            } else {
              params.push(`${key}=${this.form[key]}`)
            }
          }
        }

        return params.join('&')
      },
      urlPath () {
        return this.queryType === 'Gauges and Logs'
          ? '/public/search/gauges/logs'
          : '/public/search/gauges'
      },
      generatedUrl () {
        return `${AwsConfig.ApiGatewayBaseURL}${this.urlPath}?${this.urlParams}`
      },
      coordinates () {
        return JSON.stringify((this.form.coordinates || []))
      },
      bounds () {
        return this.form.bounds
          ? JSON.stringify((this.form.bounds || []))
          : ''
      }
    },

    mounted () {
      this.form.logDateStart = this.from
      this.form.logDateEnd = this.to
    },

    methods: {
      /**
       * See created() lifecycle hook
       * When the map moves (zooms) take the top-right
       * and bottom-left coordinates to be used for query
       */
      setBounds ({ module }) {
        console.log('PublicMapQuery: ', module.getBounds())

        const {
          _northEast: {
            lat: neLat,
            lng: neLng
          },
          _southWest: {
            lat: swLat,
            lng: swLng
          }
        } = module.getBounds()

        this.form.bounds = [
          [neLat, neLng],
          [swLat, swLng]
        ]
      },
      async submit () {
        try {
          const response = await authRequest({
            url: this.urlPath,
            method: 'get',
            params: {
              ...this.form,
              bounds: this.bounds,
              coordinates: this.coordinates
            }
          })

          let { data = {} } = response
          this.results = data
        } catch (e) {
          throw new Error(e.message)
        } finally {
          this.loading = false
        }
      },
      async copyUrl () {
        await copy(this.generatedUrl)
        this.copyUrlSuccess = true

        setTimeout(() => {
          this.copyUrlSuccess = false
        }, 2000)
      },
      async copyResults () {
        await copy(JSON.stringify(this.results))
        this.copyResultsSuccess = true

        setTimeout(() => {
          this.copyResultsSuccess = false
        }, 2000)
      }
    }
  }

  export default PublicMapQueryPage
</script>

<style lang="sass" src="./PublicMapQueryPage.sass"></style>